
PNotify.defaults.styling = 'bootstrap4';
PNotify.defaults.icons = 'fontawesome5';
PNotify.defaults.delay = 5000;

PNotify.defaults.titleTrusted = true;
PNotify.defaults.textTrusted = true;

PNotify.modules.Animate.defaults.animate = true;
PNotify.modules.Animate.defaults.inClass = 'bounceInDown';
PNotify.modules.Animate.defaults.outClass = 'bounceOut';
