export default class Home {

    constructor() {

    }

    init() {

        // collapse les plus
        if ($(window).width() < 768) {
            $('.detail').hide();
        }

        $('.plus').on('click', function (e) {
            e.preventDefault();
            $(this).parents('.blocLesPlus').find('.commentairePlus > small').fadeToggle('slow');
            $(this).parents('.blocLesPlus').find('.detail').slideToggle();
        });

        $('.goToReservation').click(function (e) {
            e.preventDefault();
            $('#agenda').slideDown(300, function () {
                $('html, body').animate({scrollTop: $('#reservation').offset().top - 100}, 300);
            })
        });

    }


}
